import { CustomAttributes } from './CustomAttributes';
import { Parcel } from './Parcel';
import { Payment } from './Payment';
import { SelectedOffer } from './SelectedOffer';

export const InPostDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  customAttributes: CustomAttributes,
  parcel: Parcel,
  insurance: Payment,
  additionalServices: [],
  packagings: [],
  reference: '',
  isReturn: false,
  selectedOffer: SelectedOffer,
  sendingMethod: '',
  externalCustomerId: '',
  comments: '',
};
