<template>
  <div>
    <form-section :title="$t('courierSet.additionalData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getInPostDefaultValues.reference"
            :title="$t('courierSet.referenceName')"
            :field-id="'shipment.reference'"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getInPostDefaultValues.externalCustomerId"
            :title="$t('courierSet.externalCustomerId')"
            :field-id="'shipment.externalCustomerId'"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getInPostDefaultValues() {
      return this.getConfigurationSet().inPostDefaultValues;
    },
  },
};
</script>

<style scoped></style>
