export const services = [
  { id: 'inpost_locker_standard', name: 'Przesyłka Paczkomat® - standardowa' },
  { id: 'inpost_locker_pass_thru', name: 'Przesyłka Paczkomat® - Podaj Dalej' },
  { id: 'inpost_courier_standard', name: 'Przesyłka kurierska standardowa' },
  { id: 'inpost_courier_express_1000', name: 'Przesyłka kurierska z doręczeniem do 10:00' },
  { id: 'inpost_courier_express_1200', name: 'Przesyłka kurierska z doręczeniem do 12:00' },
  { id: 'inpost_courier_express_1700', name: 'Przesyłka kurierska z doręczeniem do 17:00' },
  { id: 'inpost_courier_palette', name: 'Przesyłka kurierska Paleta Standard' },
  { id: 'inpost_locker_allegro', name: 'Przesyłka Paczkomat® - Allegro Paczkomat® InPost.' },
  { id: 'inpost_courier_allegro', name: 'Przesyłka kurierska - Allegro Kurier24 InPost.' },
  { id: 'inpost_letter_allegro', name: 'Przesyłka kurierska - Allegro MiniKurier24 InPost' },
  { id: 'inpost_courier_local_standard', name: 'Przesyłka kurierska Lokalna Standardowa' },
  { id: 'inpost_courier_local_express', name: 'Przesyłka kurierska Lokalna Expresowa' },
  { id: 'inpost_courier_local_super_express', name: 'Przesyłka kurierska Lokalna Super Expresowa' },
  {
    id: 'inpost_courier_c2c',
    name: 'Przesyłka kurierska - InPost Kurier C2C (usługa dla klienta detalicznego - prepaid)',
  },
];
