export const inpostAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Pobranie za przesyłkę.',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki.',
    parameters: true,
    remark: '',
  },
  {
    id: 207,
    serviceId: 'SMS',
    name: 'Serwis SMS',
    description: 'Powiadomienie o przesyłce via SMS.',
    parameters: false,
    remark: '',
  },
  {
    id: 206,
    serviceId: 'EMAIL',
    name: 'Serwis e-mail',
    description: 'Powiadomienie o przesyłce via e-mail.',
    parameters: false,
    remark: '',
  },
  {
    id: 115,
    serviceId: 'SATURDAY_DELIVERY',
    name: 'Doręczenie sobota',
    description: 'Doręczenie przesyłki w sobotę.',
    parameters: false,
    remark: '',
  },
  {
    id: 112,
    serviceId: 'DELIVERY_BETWEEN_17_20',
    name: 'Doręczenie w godzinach 17:00 - 20:00',
    description:
      'Doręczenie pomiędzy 17:00 a 20:00 obejmuje doręczenie przesyłki do Odbiorcy w dni robocze w godzinach wieczornych pomiędzy 17:00 a 20:00',
    parameters: false,
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: 'Zwrot dokumentów',
    parameters: false,
    remark: '',
  },
];
