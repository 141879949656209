import { InPostDefaultValues } from './InPostDefaultValues';

export const InPost = {
  id: '',
  clientEntityState: 4,
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  metadata: [],
  courier: '',
  courierCode: 'inpost',
  integration: 'InPost',
  login: '',
  organizationId: '',
  urlAddress: 'https://api-shipx-pl.easypack24.net',
  sendingMethod: '',
  service: '',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  inPostDefaultValues: InPostDefaultValues,
};
